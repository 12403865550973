import { faChartBar, faClock, faCode, faUser } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import {CoursePageFull} from "../components/course-pages/CoursePageFull"
import { graphql, StaticQuery } from "gatsby"
import { SHARED_COURSE_CONTENT } from "../components/shared/courses-text"

export default class CorsoMasteringAngular extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            angular_mastering: file(relativePath: { eq: "courses/courses-logos/angular-mastering.png" }) { ...thumb },

            carouselIntro1: file(relativePath: { eq: "courses/courses-carousel/angular-mastering.png" }) { ...imgTrainingBig },

            review_1: file(relativePath: { eq: "courses/courses-reviews/angular-mastering/review_mastering_angular_1.png" }) { ...imgTrainingBig },
            review_2: file(relativePath: { eq: "courses/courses-reviews/angular-mastering/review_mastering_angular_2.png" }) { ...imgTrainingBig },
            review_3: file(relativePath: { eq: "courses/courses-reviews/angular-mastering/review_mastering_angular_3.png" }) { ...imgTrainingBig },
            review_4: file(relativePath: { eq: "courses/courses-reviews/angular-mastering/review_mastering_angular_4.png" }) { ...imgTrainingBig },
            review_angular_mastering_10: file(relativePath: { eq: "courses/courses-reviews/angular-mastering/review_angular_mastering_10.png" }) { ...imgTrainingBig },
            review_angular_mastering_12: file(relativePath: { eq: "courses/courses-reviews/angular-mastering/review_angular_mastering_12.png" }) { ...imgTrainingBig },


            mode1: file(relativePath: { eq: "courses/courses-mode-images/angular-mastering-mode1.png" }) { ...imgMd },
            mode2: file(relativePath: { eq: "courses/courses-mode-images/angular-mastering-mode2.png" }) { ...imgMd },
            mode3: file(relativePath: { eq: "courses/courses-mode-images/angular-mastering-mode3.png" }) { ...imgMd },

            pricing_bg: file(relativePath: { eq: "courses/pricing/angular-mastering.png" }) { ...imgMd },

          }
        `}
        render={data => <CoursePageFull {...this.props}  data={data} course={COURSE_INFO}/> }
      />
    )
  }
}

export const COURSE_INFO = {
  theme: {
    separatorHeaderColor1: '#dd0031',
    separatorHeaderColor2: '#c3002f',
    separatorColor1: '#dd0031',
    separatorColor2: '#4a90e2',
    buttonColor: '#dd0031',
    panelBackground1: '#E66161',
    iconColor1: '#666',       // summary
    iconColor2: '#dd0031',    // faq
    footerVisible: true,
    navbarVisible: true,
  },
  header: {
    titleSEO: 'Corso Mastering Angular: approfondimento sul framework per la creazione di progetti modulari, scalabili e performanti in Angular',
    title: '<strong>MASTERING</strong> ANGULAR',
    shortTitle: '<strong>MASTERING</strong> </br>ANGULAR',
    subtitle: 'Due giornate di approfondimento sul framework per la creazione di progetti modulari, scalabili e performanti, applicando pattern e best practice',
    image: 'angular_mastering',
    difficulty: 'Intermedio',
    duration: '16 ore'
  },
  description: [
    { title: 'INTRODUZIONE', text: 'Acquisire il know-how necessario per la creazione di progetti Angular di una certa complessità applicando pattern, best practice e affrontando diversi aspetti fondamentali nella creazione di Single Page Application: gestire lo <strong> stato applicativo</strong>, autenticazione <strong>JWT e sicurezza</strong>, ottimizzazione <strong>performance</strong> e bundle-size, creare <strong>nested routes</strong> e applicare strategie di <strong>lazy loading</strong>, creazione <strong>codice scalabile ed efficiente</strong>, organizzare e strutturare l\'applicazione in <strong>componenti e moduli</strong>.'},
    /*    { title: 'DURATA', text: 'Il corso ha una durata variabile tra le 16 e le 24 ore, quindi 2 o 3 giornate: <br /><br />• 16 ore: il corso sarà intensivo, con un ritmo molto veloce e ci concentreremo sui contenuti <br/>• 24 ore: ritmo più lento, argomenti affrontati in modo più approfondito, gli studenti potrano eseguire alcune esercitazioni e configurare l\'ambiente di lavoro'},*/
    { title: 'OBIETTIVO DEL CORSO', text: '<strong>Approfondimento sul framework, utilizzo di pattern e best practice</strong>: <br /> approccio component-based; applicazioni modulari tramite la creazione di custom <code>ngModules</code> (core, features, shared); approfondimento su router, guardie, lazy loading e nested routes; gestire l\'intero processo di autenticazione; approfondimenti su componenti, direttive e depedency injection; ottimizzazione performane; manipolazione DOM  e molto altro.'},
    { title: 'REQUISITI', text: 'Conoscere le fondamenta del framework: binding, direttive incluse nel framework, form, dependency injection e comunicazione con REST API, ovvero gli argomenti alla base del corso introduttivo <a href="../corso-angular-core-concepts/">Angular Core Concepts</a>' },
  ],
  metadata: [
  ],
  carousels: {
    main: [
      {img: 'carouselIntro1'},
      /*{img: 'carouselIntro1', desc: 'Un abuso della dependency injection porta spesso ad uno stato unpredictable'},*/

    ],
    reviews: [
      {img: 'review_angular_mastering_10', desc: ''},
      {img: 'review_angular_mastering_12', desc: ''},
      {img: 'review_2', desc: ''},
      {img: 'review_3', desc: ''},
      {img: 'review_4', desc: ''},
    ],
  },
  summary: [
    { icon: faClock, label: 'Durata:', value: '16 ore' },
    { icon: faChartBar, label: 'Livello:', value: 'Intermedio' },
    { icon: faCode, label: 'Versione Angular:', value: '14.x' },
    { icon: faCode, label: 'Versione Typescript:', value: '4.x+' },
    { icon: faUser, label: 'Tipologia:', value: 'in aula o da remoto' },
  ],
  exampleLesson: {
    enabled: true,
    title: 'ESEMPIO LEZIONE',
    desc: 'In questa lezione:',
    video1: '_Ya2cAYuFII',
    bgColor: '#dd0031',
    items: [
      { text: 'Utilizzo della CLI (Command Line Interface)'},
      { text: 'Generazione di componenti e moduli custom'},
      { text: 'Approccio component-based'},
      { text: 'Organizzazione progetti modulari'},
      { text: 'Creazione di “features” custom modules'},
    ]
  },
  mode: {
    colLeft: {
      img: 'mode1',
      title: 'TEORIA',
      text: 'Approfondimento su API, tecniche e funzionalità del framework. Decine di slide, diagrammi e materiale esclusivo incluso nel corso'

    },
    colCenter: {
      img: 'mode2',
      title: 'LIVE CODING',
      text: 'La maggior parte degli argomenti sono esposti tramite sessioni di live coding, affrontando scenari reali e creando codice riutilizzabile',

    },
    colRight: {
      img: 'mode3',
      title: 'REAL APPS',
      text: 'I concetti descritti durante il corso saranno messi in pratica tramite la realizzazione di una Single Page Application'
    }
  },
  program: {
    colLeft: [
      { type: 'title', value: 'Custom Components' },
      { value: 'Input properties' },
      { value: 'Output Event emitters' },
      { value: 'Content Projection' },
      { value: 'Sibling Components communication' },
      { value: 'Comunicazione tra componenti "padre" e "figli"' },
      { value: 'Componenti riutilizzabili: Collapsable, Tabbar, Map, ...' },
      { value: 'Component-based approach: organizzare la UI in componenti stateless' },
      { value: 'Stateful vs Stateless components' },


      { type: 'title', value: 'Approfondimenti sul Router' },
      { value: 'API, componenti e direttive fornite dal modulo router' },
      { value: 'Creare applicazioni multiview' },
      { value: 'Creare custom modules per le regole di routing' },
      { value: 'Gestire route con parametri' },
      { value: 'Router e Lazy Loading' },
      { value: 'Children Routes' },
      { value: 'Nested Routes e multipli router-outlet ' },
      { value: 'Router Events' },


      { type: 'title', value: 'Manipolazione DOM in Angular' },
      { value: 'DOM e local template reference variables' },
      { value: '@ViewChild and DOM references' },
      { value: 'Il servizio ElementRef e nativeElement' },
      { value: 'Introduzione: direttive custom' },
      { value: 'Introduzione: creazione custom Pipe' },
    /*

      { type: 'title', value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
*/
    ],
    colRight: [
      { type: 'title', value: 'Organizzazione modulare dei progetti' },
      { value: 'Strutture progetti scalabili e manutenibili'},
      { value: 'Creazione applicazioni modulari e custom ngModule' },
      { value: 'Applicazioni modulari e custom ngModule' },
      { value: 'Differenze tra moduli Core, Shared & Features' },
      { value: 'Domain vs Widget vs Service Modules' },
      { value: 'Build & Deploy' },
      { value: 'Convenzioni, styleguide, best practice'},

      { type: 'title', value: 'Data Architectures in Angular' },
      { value: 'State management con servizi e dependency injection ', },
      { value: 'Gestione Stato e Side Effect', },
      { value: 'Immutabilità? Perchè è utile e quando? ', },
      { value: 'Ottimizzazione performance con le strategie di ChangeDetection onPush', },
      { value: 'Bad (e Good) Practices nell\'utilizzo della Dependency Injection', },

      /*{ value: 'Utilizzare Observable e RxJS per gestione e manipolazione dei dati' },*/
      /*{ value: 'Panoramica sugli state manager: Redux e NGRX' },*/


      { type: 'title', value: 'Sicurezza e Authentication' },
      { value: 'Autenticazione con JWT' },
      { value: 'Procedure di Login e Logout' },
      { value: 'Utilizzo REST API protette da token' },
      { value: 'Creare HTTP “interceptors”' },
      { value: 'Gestione errori HTTP' },
      { value: 'CORS, preflight e OPTIONS' },
      { value: 'Router Guards: protezione routes' },
      { value: 'Local Storage & Auto-login' },
      { value: 'Creare direttive custom per la gestione dei ruoli utente' },

    ],
  },
  pricing: {
    price: null,
    priceOffer: null,
    priceOfferDescription: null,
    items: [
      { label: 'Corso di formazione on-site', extra: '16 ore'},
      { label: 'CheatSheet Angular', extra: 'PDF formato A4'},
      { label: 'CheatSheet Javascript ES6', extra: 'PDF formato A4'},
      { label: 'Centinaia di slides', extra: 'Accesso Area Studenti'},
      { label: 'Esercitazioni post-corso', extra: 'Accesso Area Studenti'},
      { label: 'Codice Sorgente', extra: 'Incluso'},
      { label: 'Attestato di partecipazione', extra: 'PDF formato A4'},
    ],
    button: {
      enabled: true,
      visible: true,
      buyLink: '/forms/contact-form?subject=1',
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_self',
      scrollToID: ''
    },
    bg: 'pricing_bg',
  },
  newsletter: {
    enabled: true,
    campaignFormUrl: 'https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21',
  },
  actionButtons: {
    main: {
      enabled: true,
      visible: true,
      buyLink: null,
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_scrollTo',
      scrollToID: '#pricingPanel'

    }
  },
  faq: SHARED_COURSE_CONTENT.faq,
  videos: {
    teaser1: 'ElCt-rYJ4P8',
    teaser1Text: `
Ciao,
mi chiamo Fabio Biondi, sono Google Developer Expert in web technologies e Angular.
<br /><br />
Mastering Angular è in assoluto uno dei corsi più apprezzati da quando mi occupo di formazione.
Il motivo principale del suo successo è che questo corso permette agli sviluppatori che già utilizzano
Angular di fare un salto di qualità notevole nella creazione di codice manutenibile, scalabile e più
performante attraverso l'utilizzo di best practice, pattern e con molti approfondimenti sul  framework.
<br /><br />
Durante la prima parte del corso darò ampio spazio alla creazione di componenti analizzando
funzionalità come input, output, content projection e LifeCycle, mettendo in pratica i concetti
tramite la creazione diversi componenti riutilizzabili come Collapsable Panel, TabBar, Mappe e molto altro.
Gli stessi concetti saranno poi sfruttati per organizzare i template HTML delle view tramite un approccio
component-based e creando applicazioni quasi totalmente stateless.
<br /><br />
Evitare l'abuso della dependency injection, organizzare il codice per la gestione di side effect e stato applicativo
in un unico punto piuttosto che disperderlo tra vari componenti e organizzare successivamente l'applicazione  in
custom modules, ovvero collezioni di componenti, servizi, direttive e pipe, non solo porterà
vantaggi in termini di performance, soprattutto se in connubio con l'utilizzo del concetto di immutabilità e alcune
strategie di change detection per l'ottimizzazione dei rendering dei componenti, ma renderà il codice
più leggibile, manutenibile, scalabile e permetterà in futuro di migrare facilmente a state manager
più sofisticati come Redux e NGRX, senza dover stravolgere l'attuale applicazione o effettuare
complesse e rischiose operazioni di refactoring.
<br /><br />
Un approccio modulare, suddividendo l'applicazione in moduli core, shared e features,
semplificherà inoltre sia l'integrazione del lazy loading, per evitare che il bundle generato per la distribuzione
abbia una dimensione troppo elevata,
sia un utilizzo del router più avanzato, organizzando le viste (dette features usando terminologia angular) in
sotto viste, creando router-outlet nidificati, proteggendo intere sezioni e sotto-sezioni da accessi
non desiderati e agevolerà l'integrazione di state manager come NGRX che integrano funzionalità dedicate
<br /><br />
Ampio spazio sarà dato inoltre all'aspetto sicurezza, affrontando passo-passo tutti
gli aspetti legati al processo di autenticazione: login, gestione token, interceptor, protezione view
attraverso le guardie del router, processi di autologin, gestione ruoli e molto altro.
<br /><br />
Per apprezzare il contenuto di questo corso è preferibile che i partecipanti abbiano già utilizzato
Angular per un po' di tempo o si siano iscritti in precedenza al corso Angular Core Concepts
    `
  },
}
